@font-face {
    font-family: 'Poster Gothic';
    src: url('../fonts/Poster Gothic ATF Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Museo';
    src: url('../fonts/MuseoSansCondensed-900.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
  }